import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Layout from '../components/Layout'
import {uploadImage} from '../api'

// import makeAnimated from 'react-select/lib/animated'
// import Select from 'react-select'

import { Col, Container, Form, FormGroup, FormText, Label, Input, Row, UncontrolledAlert } from 'reactstrap'
import {createMovie} from '../redux/actions'
import {GENRES_TABLE_FR} from '../const'
import AvatarEditor from 'react-avatar-editor'


let i = -1
const genres = GENRES_TABLE_FR.map(genre => {
  i++
  return { value: i, label: genre }
})
genres.splice(0,1)

const shapeData = state => {
  const genres = state.genres.map(i => i.value)
  const images = [
    {image: state.posterInput, size: 'poster', movie: undefined}, 
    {image: state.coverInput, size: 'cover', movie: undefined}, 
  ]
  return {
    title: state.title,
    synopsis: state.synopsis,
    language: 'fr',
    images: images,
    movie: {
      original_title: state.originalTitle,
      release: '1999-01-01',
      genres: genres,
    } 
  }
}

class PosterEditor extends React.Component {
  static propTypes = {
    imgName: PropTypes.string,
    file: PropTypes.string,
    onSave: PropTypes.func,
  }

  onClickSave = async () => {
    if (this.editor) {
      const canvasScaled = await this.editor.getImageScaledToCanvas().toDataURL()
      this.props.onSave(this.props.imgName, canvasScaled)
    }
  }

  setEditorRef = (editor) => this.editor = editor

  render() {
    return (
      <React.Fragment>
        <AvatarEditor
          image={this.props.file}
          ref={this.setEditorRef}
          width={this.props.width}
          height={this.props.height}
          border={50}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={1}
          rotate={0}
        />
        <button className="btn btn-success helsinki w-100" onClick={this.onClickSave}>ajuster</button>
      </React.Fragment>
    )
  }
}

class AddMovie extends React.Component {
  static propTypes = {
    createMovie: PropTypes.func,
    token: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.posterInput = React.createRef();
    this.coverInput = React.createRef();
    this.state = {
      title: null,
      originalTitle: null,
      genres: null,
      synopsis: null,
      posterInput: null,
      coverInput: null,
      posterInputEdited: false,
      coverInputEdited: false,
    }
  }

  componentDidUpdate(){}

  handleGenreChange = (newValue, actionMeta) => {
    // Genres is of shape [{value: 1, label: Action}, ...]
    this.setState({genres: newValue})
  }

  handleInputChange = (event) => {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({[name]: value})
  }

  handleSubmit(event) {
    event.preventDefault()
    const movieData = shapeData(this.state)
    const images = movieData.images
    delete movieData.images
    this.props.createMovie(movieData, images, this.props.token)
  }

  handleCropImage = (name, file) => {
    const editedName = [name] + 'Edited'
    this.setState({
      [name]: file,
      [editedName]: true,
    })
  }

  imageChangedHandler = (event) => {
    const file = event.target.files[0]
    const name = event.target.name
    const editedName = [name] + 'Edited'
  
		this.setState({
      [name]: file,
      [editedName]: false,
      posterInputEdited: true, // Color on upload even if not cropped
    })
  }

  render() {
    return(
      <Layout>
        <Form onSubmit={this.handleSubmit}>
          <Container>
            <Row>
              <Col sm={{ size: 6, offset: 3 }} >
                <h2 className="helsinki mt-5 mb-4">Ajouter un film</h2>
                <UncontrolledAlert color="danger">
                  Avant d'ajouter un film, vérifiez qu'il n'existe pas déjà sur le site.
                </UncontrolledAlert>
                <FormGroup>
                  <Label for="title">Titre</Label>
                  <Input type="text" name="title" id="title" placeholder="Titre du film" value={this.state.title} onChange={this.handleInputChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="originalTitle">Titre original</Label>
                  <Input type="text" name="originalTitle" id="originalTitle" placeholder="Titre original du film" value={this.state.originalTitle} onChange={this.handleInputChange}/>
                </FormGroup>
                <FormGroup>
                  <Label>Genre(s)</Label>
                  {/* <Select
                    closeMenuOnSelect={false}
                    components={makeAnimated()}
                    onChange={this.handleGenreChange}
                    isMulti
                    placeholder="Action, aventure, animation etc..."
                    options={genres}
                  /> */}
                </FormGroup>
                <FormGroup>
                  <Label for="exampleText">Synopsis</Label>
                  <Input type="textarea" name="synopsis" id="synopsis" value={this.state.synopsis} onChange={this.handleInputChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="poster">Affiche du film</Label> <br />
                  <input className={this.state.posterInputEdited && "text-success"}  type="file" ref={this.posterInput} name="posterInput" onChange={this.imageChangedHandler}/>
                  {/* { this.state.posterInput && !this.state.posterInputEdited &&
                    <PosterEditor file={this.state.posterInput} imgName="posterInput" onSave={this.handleCropImage}  height={468.25} width={318}/> //  height={268} width={182}
                  } */}

                </FormGroup>
                <FormGroup>
                  <Label for="poster">Vignette du film</Label> <br />
                  <input className={this.state.coverInputEdited && "text-success"} type="file" ref={this.coverInput} name="coverInput" onChange={this.imageChangedHandler}/>
                  { this.state.coverInput && !this.state.coverInputEdited &&
                    <PosterEditor file={this.state.coverInput} onSave={this.handleCropImage} imgName="coverInput" height={144} width={256}/>
                  }
                </FormGroup>
                <button type="submit" className="btn btn-link btn-sm float-right">Ajouter</button>
              </Col>
            </Row>
          </Container>
        </Form>
      </Layout>
    )
  }
}

/*  Expected shape
{
    "images": [],
    "movie": {
        "original_title": "",
        "original_language": null,
        "budget": null,
        "box_office": null,
        "runtime": null,
        "release": null,
        "genres": null
    },
    "slug": "",
    "title": "",
    "language": null,
    "synopsis": ""
}
*/

const mapStateToProps = state => ({
  token: state.user.token,
})


export default connect(mapStateToProps, {createMovie})(AddMovie)